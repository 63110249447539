._3T-5I {
  position: fixed;
  z-index: 250;
  /* background-color: black; */
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(6, 75, 6, 0.5);
}

._3T-5I._RR3IS {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 550;
  background: rgba(6, 75, 6, 0.5);
}

._3T-5I._34DOM {
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(6, 75, 6, 0.5);
}

._EJ1Zp {
  font-size: xx-large;
  color: var( --color-highlight );
  background-color: var( --background-color );
  border: 1px solid var( --border-color );
  position: fixed;
  right: 10px;
  top: 8px;
  width: 40px;
  text-align: center;
  cursor: pointer;
}
._3hPPe {
    background: var( --button-background );
    border: none;
    color: var( --button-color );
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    border-radius: var( --button-radius );
}
._3hPPe:hover {
    background: var( --button-hover-background );
}
._3E2t3 {
    background-color: rgba(59, 89, 152, 1);
}
._3E2t3:hover {
    background-color: rgba(59, 89, 152, 0.85);
}
._sClGs {
    height: 22px;
    width: 22px;
    margin: -5px 5px -5px -2px;
}
/*
.Button:first-of-type {
    margin-left: 0;
    padding-left: 0;
}
*/

._3m8bO {
    position: relative;
}

._TTV-R {
    border: 1px solid var( --input-inactive-background-color );
}

._2j7wX {
    color: var( --color-highlight );
    background-color: var( --list-changed-background-color );
    box-shadow: 0 0 2px 2px grey inset;
/*    box-shadow: -3px -3px 5px 5px black inset; */
}

._lyRhy {
    height: calc( 100% - 10px );
    width: calc( 100% - 10px );
    padding: 5px;
}

._3xpRU {
    color: rgba( 0, 0, 0, 0.3 );
}

._362YN {
    position: absolute;
    top: 0;
    left: 0;
    height: calc( 100% - 10px );
    width: calc( 100% - 10px );
    border: none;
    font-family: var( --detail-font-family );
    font-size: var( --detail-font-size );
    font-weight: var( --detail-font-weight );
    padding: 5px;
    z-index: -20;
}

._3IZI3 {
    position: absolute;
    top: 0;
    right: 3px;
    cursor: pointer;
}

._3p9NS {
    cursor: pointer;
    width: 90%;
    height: 25px;
    border-radius: 5px;
    background-color: rgba( 255, 255, 255, 0.4 );
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    margin: 5px;
}
._3WbaN,
._1X4Fj {
    background: var( --list-background-color );
    border: none;
    color: var( --button-color );
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 5px;
    font-weight: bold;
    border-radius: var( --button-radius );
    position: relative;
}
._3BdKl {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    background-color: var( --button-background );
}
._3BdKl:hover {
    background: var( --button-hover-background );
}

._2ftnp {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

._asPJZ,
._3WbaN {
    cursor: default;
    opacity: .5;
    margin: 0;
    display: inline;
    padding: 10px 0;
}

._2LZwr {
    opacity: 0;
    margin-bottom: 10px;
}

._3-7-d {
    height: 150px;
    margin-bottom: 10px;
}

._3x9aC {
    color: var( --color-highlight );
    font-weight: bold;
}

._3MFId {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

._LAbHa {
    color: black;
    background-color: var( --input-background-color );
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    height: 18px;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid var( --border-color );
    border-radius: 10px;
}
._1mWA6,
._1rdvK {
    text-align: end;
    display: flex;
    border: none;
    background-color: inherit;
    font: inherit;
}
._1mWA6 {
    width: 230px;
}
._1mWA6._1bKwj {
    text-align: center;
}
._32id2 {
    margin-right: 0;
}
._2XL7w {
    z-index: 550;
    background: rgba(100, 0, 100, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

._aRQqd {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: grey;
    background: linear-gradient(to right, grey 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: _35xgN 1.4s infinite linear;
    animation: _35xgN 1.4s infinite linear;
    transform: translateZ(0);
  }
  ._aRQqd:before {
    width: 50%;
    height: 50%;
    background: grey;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  ._aRQqd:after {
    background: #0dc51c;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes _35xgN {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes _35xgN {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
._3UR2C {
    position: fixed;
    z-index: 500;
    background-color: var( --list-background-color );
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

._2S-Mo {
    border-radius: 10px;
}

@media (min-width: 600px) {
    ._3UR2C {
        width: 500px;
        left: calc(50% - 250px);
    }
}

._t8L1D {
    position: relative;
    z-index: 500;
    background-color: rgb(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
}
._16375 {
    opacity: 0;
}

._3H6XK {
    opacity: 0;
}
._3hSXu {
    opacity: 1;
    transition: opacity 1s ease-out;
}
._3tIqm {
    opacity: 1;
    transition: all 1s ease-out;
}

._2-mrG {
    opacity: 1;
}
._HMpo- {
    opacity: 0;
    transition: opacity 1s ease-in;
}

._11YTH {
    margin: 10px auto;
    width: 232px;
}

._11YTH:disabled {
    opacity: 0.25;
}

._1VyzM {
    width: 200px;
    border-width: 2px;
}

._2IGwe {
    margin: 4px auto;
    border: 2px solid;
    border-color: rgba(6, 75, 15, 0.5);
    border-radius: 5px;
    background-color: white;
    color: rgba(6, 75, 15, 1);
    width: 209px;
    font-weight: bold;
    padding: 10px;
    box-shadow: none;
    outline-width: 0;
}
._2IGwe:focus {
    border-color: rgba(6, 75, 15, 1);
}
._2IGwe:-moz-read-only {
    border-color: rgba(6, 75, 15, 0.5);
    background-color: burlywood;
}
._2IGwe:read-only {
    border-color: rgba(6, 75, 15, 0.5);
    background-color: burlywood;
}
._3FyhX {
    border-color: rgba(224, 64, 15, 0.863);
}
._3FyhX:focus {
    border-color: rgba(224, 64, 15, 1);
}

._ZNhTj {
    padding: 15px inherit 15px inherit;
    display: flex;
    flex-flow: column;
}

._1cpgf {
    text-align: center;
    color: rgb(224, 64, 15 );
    font-size: large;
    font-weight: bold;
}
._1buWS {
    color: var( --error-color );
    background-color: var( --error-background-color );
}

._3MeS6 {
    color: var( --color-highlight );
    background-color: slategray;
    border-radius: var( --border-radius );
    padding: 10px;
}

._1Ekay {
    cursor: pointer;
    font-size: 150%;
    font-family: var( --header-font-family );
    font-weight: var( --header-font-weight );
}
._2hGU9 {
    color: black;
    font-weight: bold;
    background-color: var( --list-background-color );
    display: flex;
    flex-flow: column;
}
._15QcT {
    margin: 10px 0;
    padding: 10px;
}

._1SyBi {
    font: inherit;
    font-weight: bold;
    background-color: var( --input-background-color );
    height: 18px;
    margin: 10px;
    padding: 10px;
    border: 1px solid var( --border-color );
    border-radius: 10px;
}

._28IBO {
    font-weight: bold;
    color: var( --color-highlight );
}


._3YFuH {
    cursor: pointer;
    font-size: 150%;
    font-family: var( --header-font-family );
    font-weight: var( --header-font-weight );
}
._34wmT {
    display: flex;
    flex-flow: row;
}
@media (orientation:portrait) {
    ._34wmT {
        flex-flow: column;
    }
}

._Fe2wD {
    position: relative;
}

._3jBQG {
    border: 1px solid var( --input-inactive-background-color );
}

._PCfJF {
    color: var( --color-highlight );
    background-color: var( --list-changed-background-color );
    box-shadow: 0 0 2px 2px grey inset;
/*    box-shadow: -3px -3px 5px 5px black inset; */
}

._3_NCj {
    height: calc( 100% - 10px );
    width: calc( 100% - 10px );
    padding: 5px;
}

._3JuJC {
    display: flex;
    flex-flow: row;
}
@media (orientation:portrait) {
    ._2lb-4 {
        flex-flow: column;
        margin-left: 30px;
    }
}
._2UNi1 {
    height: 45px;
    width: 45px;
    cursor: pointer;
}
._2N1Jl {
    height: 38px;
    max-width: 38px;
    margin-left: auto;
    cursor: pointer;
}
._pu1c9 {
    height: 25px;
    max-width: 25px;
}

._wIvfj {
    background-color: white;
}

._1LTvn,
._VSQqh {
    width: 80%;
    height: 80%;
    overflow: hidden;
    display: flex;
}
._1LTvn {
    flex-flow: row;
    justify-content: space-between;
    margin: auto;
    position: relative;
}
._1qNcA {
    width: 0px;
}
._2noFw {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
}
._2-T4a {
    position: relative;
}
._1KNpd {
    border: 1px solid var( --input-inactive-background-color );
}

._1jG7a,
._2TD-3 {
    height: calc( 100% - 10px );
    width: calc( 100% - 10px );
    padding: 5px;
}
._1jG7a {
    cursor: pointer;
}

._111hG {
    position: absolute;
    top: 0;
    left: 0;
    height: calc( 100% - 10px );
    width: calc( 100% - 10px );
    border: none;
    font-family: var( --detail-font-family );
    font-size: var( --detail-font-size );
    font-weight: var( --detail-font-weight );
    padding: 5px;
    z-index: -20;
}
._lW2kj {
    text-align: right;
}

._2nWxk {
    width: 100%;
    height: 100%;
}

._2xnsX {
    z-index: 20;
}

._2-8q8 {
    color: var( --color-highlight );
    background-color: var( --list-changed-background-color );
    box-shadow: 0 0 2px 2px grey inset;
/*    box-shadow: -3px -3px 5px 5px black inset; */
}

._3pB4h {
    font-weight: 900;
}

._1Bi91 {
    position: absolute;
    top: 0;
    right: 3px;
    cursor: pointer;
}

._3gMPN {
    outline: none
}
._2boQJ {
    color: rgba(5, 5, 46, 0.3);
}

._1sPjS {
    color: var( --header-color );
    display: flex;
    flex-flow: row;
    width: calc( var( --width-timestamp ) + 80px );
    padding: 5px;
    text-align: center;
}
._1sPjS div:first-child {
    width: var( --width-timestamp );
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0);
}
._1sPjS div._2-8q8:first-child {
    cursor: pointer;
}
/* TODO - separate SAVE button from timestamp and suppress timestamp on hand-helds */

._1sPjS div:last-child {
    width: 80px;
    margin-left: 5px;
}

._37Fs9 {
    cursor: pointer;
    font-size: 150%;
    font-family: var( --header-font-family );
    font-weight: var( --header-font-weight );
}
._37exH {
    text-align: right;
    height: 28px;
}
._2y3yo {
    cursor: pointer;
    width: 90%;
    height: 25px;
    border-radius: 5px;
    background-color: rgba( 255, 255, 255, 0.4 );
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    margin: 5px;
}
._1peag {
    margin-top: 10px;
    margin-right: 10px;
    max-height: 45px;
    max-width: 45px;
    opacity: 0.3;
    cursor: pointer;
}

._puZhq {
    opacity: 1;
}

._2I9Yw {
    border-radius: 4px;
    margin-top: 10px;
    margin-right: 10px;
    height: 45px;
    width: 45px;
    cursor: pointer;
}
._3yvaT {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
          animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0,0,0,0.08);
}
._3yvaT._3DLZL,
._3yvaT._2VOWu {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
._3yvaT._3DLZL {
  display: inline-block;
  z-index: 99999;
}
._3yvaT._1AFNk._3DLZL {
  -webkit-animation: _QwZRS 300ms cubic-bezier(0.23, 1, 0.32, 1);
          animation: _QwZRS 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
._3yvaT._2VOWu {
  display: block;
  position: relative;
  top: 2px;
}
._3yvaT._pn6QS {
  position: absolute;
  top: calc(100% + 2px);
}
._3yvaT._pn6QS._3DLZL {
  z-index: 999;
  display: block;
}
._3yvaT._2t8Bp ._vysYx ._1x8o7:nth-child(n+1) ._wkwkH._1zmHd:nth-child(7n+7) {
  box-shadow: none !important;
}
._3yvaT._2t8Bp ._vysYx ._1x8o7:nth-child(n+2) ._wkwkH._1zmHd:nth-child(7n+1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
._3yvaT ._3ZJ6i ._1x8o7,
._3yvaT ._1NwnV ._1x8o7 {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
._3yvaT ._3ZJ6i ._1x8o7 {
  border-left: 0;
}
._3yvaT._OhiMU._1NwnV ._1ag-K {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}
._3yvaT._15Dtv._1NwnV ._1ag-K {
  height: auto;
}
._3yvaT:before,
._3yvaT:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}
._3yvaT._1hVYA:before,
._3yvaT._1hVYA:after {
  left: auto;
  right: 22px;
}
._3yvaT:before {
  border-width: 5px;
  margin: 0 -5px;
}
._3yvaT:after {
  border-width: 4px;
  margin: 0 -4px;
}
._3yvaT._1I6x0:before,
._3yvaT._1I6x0:after {
  bottom: 100%;
}
._3yvaT._1I6x0:before {
  border-bottom-color: #e6e6e6;
}
._3yvaT._1I6x0:after {
  border-bottom-color: #fff;
}
._3yvaT._3_UnM:before,
._3yvaT._3_UnM:after {
  top: 100%;
}
._3yvaT._3_UnM:before {
  border-top-color: #e6e6e6;
}
._3yvaT._3_UnM:after {
  border-top-color: #fff;
}
._3yvaT:focus {
  outline: 0;
}
._1I5SM {
  position: relative;
  display: inline-block;
}
._2-SbK {
  display: flex;
}
._2-SbK ._3orX4 {
  background: transparent;
  color: rgba(0,0,0,0.9);
  fill: rgba(0,0,0,0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  flex: 1;
}
._2-SbK ._3KjcK,
._2-SbK ._2a_qC {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0,0,0,0.9);
  fill: rgba(0,0,0,0.9);
}
._2-SbK ._3KjcK._3bDiL,
._2-SbK ._2a_qC._3bDiL {
  display: none;
}
._2-SbK ._3KjcK i,
._2-SbK ._2a_qC i {
  position: relative;
}
._2-SbK ._3KjcK._3KjcK,
._2-SbK ._2a_qC._3KjcK {
/*
      /*rtl:begin:ignore*/
/*
      */
  left: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
._2-SbK ._3KjcK._2a_qC,
._2-SbK ._2a_qC._2a_qC {
/*
      /*rtl:begin:ignore*/
/*
      */
  right: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
._2-SbK ._3KjcK:hover,
._2-SbK ._2a_qC:hover {
  color: #959ea9;
}
._2-SbK ._3KjcK:hover svg,
._2-SbK ._2a_qC:hover svg {
  fill: #f64747;
}
._2-SbK ._3KjcK svg,
._2-SbK ._2a_qC svg {
  width: 14px;
  height: 14px;
}
._2-SbK ._3KjcK svg path,
._2-SbK ._2a_qC svg path {
  transition: fill 0.1s;
  fill: inherit;
}
._2hmyw {
  position: relative;
  height: auto;
}
._2hmyw input,
._2hmyw span {
  display: inline-block;
}
._2hmyw input {
  width: 100%;
}
._2hmyw input::-ms-clear {
  display: none;
}
._2hmyw input::-webkit-outer-spin-button,
._2hmyw input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
._2hmyw span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57,57,57,0.15);
  box-sizing: border-box;
}
._2hmyw span:hover {
  background: rgba(0,0,0,0.1);
}
._2hmyw span:active {
  background: rgba(0,0,0,0.2);
}
._2hmyw span:after {
  display: block;
  content: "";
  position: absolute;
}
._2hmyw span._1ngRA {
  top: 0;
  border-bottom: 0;
}
._2hmyw span._1ngRA:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57,57,57,0.6);
  top: 26%;
}
._2hmyw span._I2YL8 {
  top: 50%;
}
._2hmyw span._I2YL8:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57,57,57,0.6);
  top: 40%;
}
._2hmyw span svg {
  width: inherit;
  height: auto;
}
._2hmyw span svg path {
  fill: rgba(0,0,0,0.5);
}
._2hmyw:hover {
  background: rgba(0,0,0,0.05);
}
._2hmyw:hover span {
  opacity: 1;
}
._9Ntaq {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}
._9Ntaq span._2bYG9 {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
._9Ntaq span._2bYG9:hover {
  background: rgba(0,0,0,0.05);
}
._9Ntaq ._2hmyw {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}
._9Ntaq ._2hmyw span._1ngRA:after {
  border-bottom-color: rgba(0,0,0,0.9);
}
._9Ntaq ._2hmyw span._I2YL8:after {
  border-top-color: rgba(0,0,0,0.9);
}
._9Ntaq input._3CrH9 {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
._9Ntaq input._3CrH9:focus {
  outline: 0;
}
._9Ntaq input._3CrH9[disabled],
._9Ntaq input._3CrH9[disabled]:hover {
  font-size: 100%;
  color: rgba(0,0,0,0.5);
  background: transparent;
  pointer-events: none;
}
._9Ntaq ._2b-li {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}
._9Ntaq ._2b-li:focus,
._9Ntaq ._2b-li:active {
  outline: none;
}
._9Ntaq ._2b-li:hover {
  background: rgba(0,0,0,0.05);
}
._9Ntaq ._2b-li ._2S9dY {
  background-color: transparent;
  outline: none;
  padding: 0;
}
._7SSrk {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
}
._7SSrk ._3qX22 {
  display: flex;
  flex: 1;
}
span._VuTpF {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0,0,0,0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}
._1x8o7,
._1StXa {
  padding: 1px 0 0 0;
}
._vysYx {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 307.875px;
}
._vysYx:focus {
  outline: 0;
}
._1x8o7 {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
._1x8o7 + ._1x8o7 {
  box-shadow: -1px 0 0 #e6e6e6;
}
._wkwkH {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}
._wkwkH._1zmHd,
._wkwkH._OByDe._1zmHd,
._wkwkH._1gwWs._1zmHd,
._wkwkH._1LAC7._1zmHd,
._wkwkH._OByDe._1LAC7._1zmHd,
._wkwkH._1gwWs._1LAC7._1zmHd,
._wkwkH:hover,
._wkwkH._OByDe:hover,
._wkwkH._1gwWs:hover,
._wkwkH:focus,
._wkwkH._OByDe:focus,
._wkwkH._1gwWs:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
._wkwkH._1LAC7 {
  border-color: #959ea9;
}
._wkwkH._1LAC7:hover,
._wkwkH._1LAC7:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}
._wkwkH._221Zx,
._wkwkH._3Wqye,
._wkwkH._rMvtv,
._wkwkH._221Zx._1zmHd,
._wkwkH._3Wqye._1zmHd,
._wkwkH._rMvtv._1zmHd,
._wkwkH._221Zx:focus,
._wkwkH._3Wqye:focus,
._wkwkH._rMvtv:focus,
._wkwkH._221Zx:hover,
._wkwkH._3Wqye:hover,
._wkwkH._rMvtv:hover,
._wkwkH._221Zx._OByDe,
._wkwkH._3Wqye._OByDe,
._wkwkH._rMvtv._OByDe,
._wkwkH._221Zx._1gwWs,
._wkwkH._3Wqye._1gwWs,
._wkwkH._rMvtv._1gwWs {
  background: #569ff7;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
._wkwkH._221Zx._3Wqye,
._wkwkH._3Wqye._3Wqye,
._wkwkH._rMvtv._3Wqye {
  border-radius: 50px 0 0 50px;
}
._wkwkH._221Zx._rMvtv,
._wkwkH._3Wqye._rMvtv,
._wkwkH._rMvtv._rMvtv {
  border-radius: 0 50px 50px 0;
}
._wkwkH._221Zx._3Wqye + ._rMvtv:not(:nth-child(7n+1)),
._wkwkH._3Wqye._3Wqye + ._rMvtv:not(:nth-child(7n+1)),
._wkwkH._rMvtv._3Wqye + ._rMvtv:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #569ff7;
}
._wkwkH._221Zx._3Wqye._rMvtv,
._wkwkH._3Wqye._3Wqye._rMvtv,
._wkwkH._rMvtv._3Wqye._rMvtv {
  border-radius: 50px;
}
._wkwkH._1zmHd {
  border-radius: 0;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
._wkwkH._3bDiL,
._wkwkH._3bDiL:hover,
._wkwkH._OByDe,
._wkwkH._1gwWs,
._wkwkH._L-b79,
._wkwkH._L-b79._OByDe,
._wkwkH._L-b79._1gwWs {
  color: rgba(57,57,57,0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
._wkwkH._3bDiL,
._wkwkH._3bDiL:hover {
  cursor: not-allowed;
  color: rgba(57,57,57,0.1);
}
._wkwkH._3mqyv._221Zx {
  border-radius: 0;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}
._wkwkH._2le9q {
  visibility: hidden;
}
._SPeSd ._wkwkH {
  margin-top: 1px;
}
._3YBIr {
  float: left;
}
._3YBIr ._1StXa {
  padding: 0 12px;
  box-shadow: 1px 0 0 #e6e6e6;
}
._3YBIr ._VuTpF {
  float: none;
  width: 100%;
  line-height: 28px;
}
._3YBIr span._wkwkH,
._3YBIr span._wkwkH:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57,57,57,0.3);
  background: transparent;
  cursor: default;
  border: none;
}
._S_bn7 {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}
._2_qPQ {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}
._1ag-K {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}
._1ag-K:after {
  content: "";
  display: table;
  clear: both;
}
._1ag-K ._2hmyw {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}
._1ag-K ._2hmyw span._1ngRA:after {
  border-bottom-color: #393939;
}
._1ag-K ._2hmyw span._I2YL8:after {
  border-top-color: #393939;
}
._1ag-K._1dUBv ._2hmyw {
  width: 26%;
}
._1ag-K._S0NZe ._2hmyw {
  width: 49%;
}
._1ag-K input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
._1ag-K input._1DrUe {
  font-weight: bold;
}
._1ag-K input._uWOjN,
._1ag-K input._245K2 {
  font-weight: 400;
}
._1ag-K input:focus {
  outline: 0;
  border: 0;
}
._1ag-K ._2w8Pz,
._1ag-K ._1I6ne {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  align-self: center;
}
._1ag-K ._1I6ne {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
._1ag-K input:hover,
._1ag-K ._1I6ne:hover,
._1ag-K input:focus,
._1ag-K ._1I6ne:focus {
  background: #eee;
}
._2cjsn[readonly] {
  cursor: pointer;
}
@-webkit-keyframes _QwZRS {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes _QwZRS {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
