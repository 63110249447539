.Main {
    position: fixed;
    top: 65px;
    left: 0;
    width: 100%;
    height: calc( 100% - 100px );
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-flow: column;
}
@media (orientation:landscape) {
    .Main {
        width: 500px;
        left: calc( ( 100vw - 500px ) / 2 );
        margin: 0 auto;
    }
}

.Scroller {
    width: calc( 100% - 20px );
}

.CurrentHeader,
.Header,
.Product {
    display: flex;
    flex-flow: row;
    margin: 0 10px 5px 5px;
    padding: 2px 0 2px 2px;
    color: black;
    background-color: white;
    border: 1px solid rgba( 0, 0, 0, 0.6 );
    cursor: pointer;
    min-height: 110px;
}
@media (orientation:landscape) {
    .Product {
        min-height: 90px;
    }
}

.CurrentHeader,
.Header {
    min-height: 25px;
    font-size: 20px;
    font-weight: bold;
    border: 3px solid black;
    background-color: goldenrod;
    justify-content: center;
    padding-top: 5px;
}

.CurrentHeader {
    background-color: palegoldenrod;
}

.ThumbnailSize {
    width: 100px;
    max-width: 100px;
    height: 100px;
    margin-left: 0;
}
@media (orientation:landscape) {
    .ThumbnailSize {
        width: 80px;
        max-width: 80px;
        height: 80px;
    }
}

.ProductNames {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    width: 60%;
}
.ItemName {
    margin-right: 10%;
    padding: 0 0 5px 5px;
    font-family: 'Tangerine', serif;
    font-size: 33px;
}
.ItemName:last-child {
    padding-bottom: 0;
}

.PricePoints {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    margin-left: auto;
    padding: 0 5px 5px 0;
}

.VariationNames {
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 5px;
}

.Price {
    padding-bottom: 5px;
}

.Price:last-child {
    padding-bottom: 0;
}