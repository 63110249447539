.Toolbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    z-index: 50;
}

.MainBar {
    height: 65px;
    display: flex;
    flex-flow: row;
    justify-content: stretch;
    align-items: center;
    padding: 0 0 5px 12px;
    box-sizing: border-box;
}

.Toolbar nav {
    display: none;
    height: 100%;
}
@media (orientation: portrait) {
    .Toolbar nav {
        display: block;
    }
}

.MenuIcon {
    margin-top: 5px;
}

.CustomerTitle,
.Title {
    color: lightcyan;
    display: inline-block; 
    margin-left: -1px;
    font-family: 'Tangerine', serif;
    font-size:  52px;
    font-weight: 500;
    top: -15px;
}
.CustomerTitle {
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 5px;
    color: var( --color-highlight );
    background-color: whitesmoke;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 40px;
}
@media (max-width: 390px) {
    .Title {
        font-size: 40px;
    }
}
@media (max-width: 340px) {
    .Title {
        font-size: 30px;
    }
}

.Widget {
    display: inline-block; 
    width: 60px; 
    height: 63px;
}

.SearchBlock {
    margin-left: auto;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
}

.SearchInput {
    font-size: large;
    color: white;
    background:url(/pictures/other/looking-glass.svg) no-repeat right top;
    width: 150px;
}
.SearchInput::placeholder {
    color: lightgray;
}

.ClearSearch,
.NoSearch {
    background-color: rgba( 0, 0, 0, 0 );
    color: black;
    font-size: 14pt;
    font-weight: bold;
    margin-top: 4px;
    margin-left: 4px;
    cursor: pointer;
}
.NoSearch {
    color: rgba( 0, 0, 0, 0 );
}

.PickupDateBlock {
    display: flex;
    flex-flow: column;
    text-align: center;
    padding: 3px;
    margin-top: 37px;
    margin-right: 3px;
    margin-left: auto;
    margin-bottom: 34px;
    border: 1px solid rgba( 255, 255, 255, 0.5 );
    border-radius: 5px;
    background-color: rgba( 0, 0, 0, 0.1 );
    cursor: pointer;
}
.BadPickupDate {
    border: 2px solid var( --color-highlight );
    color: var( --color-highlight );
    background-color: whitesmoke;
}
.NoBorder {
    border: none;
}

.DateHeader,
.OrderTime {
    font-size: x-small;
}
.PickupDate {
    font-size: small;
}

.Cart {
    position: relative;
}

.CartCount {
    color: blanchedalmond;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: .5px solid blanchedalmond;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 15px;
    position: absolute;
    top: 10px;
    left: 17px;
}

.CartIcon {
    margin-top: 15px;
    margin-right: 10px;
    width: 45px;
    height: 35px;
}

.OutOfStockError,
.DateError {
    display: block;
    width: calc( 100% - 15px );
    display: flex;
    flex-flow: column;
    background-color: rgba( 0, 0, 0, 0.8 );
    padding: 5px;
    border: 4px solid var( --color-highlight );
}

.DateButtons {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
}

.UserName {
    max-width: 80px;
    width: 80px;
    padding-right: 3px;
    margin-left: -90px;
    pointer-events: none;
    text-align: right;
}