.Main {
    position: fixed;
    top: 65px;
    left: 0;
    width: 100%;
    height: calc( 100% - 100px );
    padding: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-flow: column;

}

.Section {
    position: relative;
    margin-bottom: -50px;
    display: flex;
    min-height: 150px;
    height: 25%;
    cursor: pointer;
    font-size: x-small;
}
@media (orientation:landscape) {
    .Section {
        height: 30%;
    }
}
.Odd {
    margin-left: 0;
    margin-right: auto;
}
.Even {
    margin-right: 10%;
    margin-left: auto;
}
@media (orientation:landscape) {
    .Odd {
        margin-left: 15%;
        margin-right: auto;
    }
    .Even {
        margin-right: 25%;
        margin-left: auto;
    }
}

.PictureSectionDiv {
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    text-align: center;
}
.PictureSection {
/*    height: 100%; */
    max-height: 100%;
    max-width: 95%;
    margin: 0 auto;
}
/*@media (orientation:landscape) {
    .PictureSection {
        height: 18vw;
    }
}*/

.SectionPictureDiv {
    height: 100%;
    min-height: 100%;
/*    max-height: 40%; */
    position: relative;
    margin-left: 5%;
    margin-right: 5%;
}
@media (orientation:landscape) {
    .SectionPictureDiv {
        height: 100%;
/*        max-width: 40vw; */
        margin-left: 20%;
        margin-right: 20%;
}
}
.SectionPicture {
    height: 100%;
    clip-path: circle(42%);
    margin-bottom: 20px;
}

.SectionHeader {
    max-width: 98%;
    position: absolute;
    bottom: 20px;
    color: black;
    text-align: right;
    -webkit-text-fill-color: blanchedalmond;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    font-size: 350%;
    font-weight: 800;
}
.Odd .SectionHeader {
    left: 15%;
}
.Even .SectionHeader {
    right: 15%;
}