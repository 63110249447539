.Modal {
    top: 5%;
    max-height: 90%;
    height: 90%
}

.Content {
    width: 90%;
    max-height: 85%;
    overflow-y: auto;
    left: 5%;
    max-width: 500px;
    font-size: small;
    background-color: white;
    margin: 10px 0 10px 0;
}

.Items {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.Open,
.Closed {
    border: 1px solid rgba( 0, 0, 0, 0.3 );
    border-radius: 5px;
    margin-bottom: 7px;
    padding: 3px;
}
.Closed {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.Stacked {
    display: flex;
    flex-flow: column;
}

.ChangeButton {
    height: 20px;
    padding: 3px;
    font-size: x-small;
    margin: 0;
}

.ItemName {
    margin-bottom: 7px;
}

.TitlePlex,
.Title {
    width: 100px;
}

.Heading {
    margin-bottom: 4px;
}

.Remove {
    height: 28px;
    width: auto;
    font-size: small;
    padding: 3px;
}

.Modifiers {
    max-height: 90%;
}

.Buttons {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-size: small;
}

.Simple {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
}

.Cancel {
    margin-left: auto;
}

.DetailHeader {
    display: none;
}
.DetailLine::before {
    content: "\2007";
}
.DetailLine::after {
    content: ",";
}
.DetailLine:last-child::after {
    content: "";
}
.DetailLine {
    display: inline-block;
}