.Legend {
    color: white;
    font-family: sans-serif;
    font-size: large;
    font-weight: bold;
}

.Message {
    font-weight: bold;
    color: var( --color-highlight );
    margin: 2px 0 2px 0;
    min-height: 18px;
}

.Label {
    width: 150px;
}

.CheckAmountInput,
.CheckNumberInput {
    font-size: large;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    margin-right: 10px;
    width: 150px;
    height: 30px;
    border-radius: 0;
    margin-left: 83px;
}
.CheckNumberInput {
    text-align: center;
}

.Disabled:disabled {
    opacity: .5;
}
