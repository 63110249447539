.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
    display: block;
}
/*
@media (min-width: 500px) {
    .NavigationItems {
        display: flex;
        align-items: center;
    }
}*/