
.OrderItem {
    display: flex;
    flex-flow: row;
    margin-bottom: 8px;
}

.Thumbnail {
    margin-left: 0;
}

.Details {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding-left: 3px;
    cursor: pointer;
}

.Item {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.Override,
.ExtendedPrice {
    margin-left: auto;
    margin-right: 5px;
}
.Override {
    border: 1px solid lightgray;
    border-radius: 3px;
}

.UnitLine {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    padding-left: 20px;
    margin-top: -1px;
    margin-bottom: 3px;
    font-size: small;
    font-weight: bold;
}

.UnitPrice {
    margin-right: 10px;
}

.MessageLine {
    display: block;
    padding-left: 20px;
    margin-top: -7px;
}

.MessageLabel {
    display: inline-block;
    font-size: x-small;
    font-style: italic;
}

.Message {
    display: inline-block;
    margin-left: 3px;
    font-size: small;
}

.DetailHeader {
    margin-top: 3px;
    margin-left: 20px;
    font-size: small;
    font-weight: bold;
}

.DetailLine {
    display: inline-block;
    margin-top: -2px;
    margin-left: 20px;
    font-size: small;
}
