.Main {
    position: fixed;
    top: 65px;
    left: 0;
    width: calc( 100% - 15px );
    height: calc( 100% - 120px );
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 0 10px 0 5px;
    padding: 5px;
    color: black;
    background-color: white;
}
.Main > h2 {
    margin-left: 20px;
}

.Orders {
    display: flex;
    flex-flow: column;
    margin-bottom: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.NoOrders {
    margin-left: 30px;
    margin-bottom: auto;
}

.Order {
    display: flex;
    flex-flow: row;
    text-align: center;
    font-size: 15px;
    font-weight: 200;
    cursor: pointer;
}

.Header {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.Date {
    width: 200px;
}

.Receipt {
    width: 150px;
}

.SendButton {
    margin: 1px;
    max-height: 20px;
    padding: 1px 3px;
}

.PickupProcedure {
    max-height: 20%;
    overflow-y: auto;
}

.Footer {
    display: block;
    width: 100%;
    padding: 5px 10px 5px 5px;
    border-top: 1px solid rgba( 0, 0, 0, 0.3 );
}
