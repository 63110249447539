.PaymentForm {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 335px;
    height: 238px;
}

.PaymentOK {
    z-index: 1000;
    color: black;
    background-color: white;
    top: 10%;
    height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
}

.OrderComplete {
    border: 3px solid rgb(28, 161, 28);
    border-radius: 5px;
    display: block;
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
}

.ReceiptButtons {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.Error {
    margin-top: auto;
    margin-bottom: 50px;
    margin-left: 15px;
    color: var( --color-highlight );
}

.PayNow,
.Cancel {
    padding-top: 7px;
    position: absolute;
    bottom: 5px;
    display: block;
    width: 336px;
    height: 30px;
    margin-left: 0;
}
.PayNow {
    height: 35px;
    bottom: 40px;
    border: 1px solid;
    border-color: black;
    background-color: teal;
}

.SquareError {
    top: 20%;
    border: 2px solid var( --color-highlight );
}
