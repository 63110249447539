body {
    color: var( --color );
    border-color: var( --border-color );
}

.Content {
    position: relative;
    top: 65px;
    padding-left: 4px;
    width: 100%;
}

/*.Content.scroll { 
    margin:4px, 4px; 
    padding:4px; 
/*    width: calc( 500px; 
    height: 110px; 
    overflow-x: hidden; 
    overflow-y: auto; 
    text-align:justify; 
/*    margin-top: 65px; 
}
*/