.SplitPaymentModal {
    width: 80%;
    left: 10%;
    right: 10%;
}

.PaymentPrompt,
.Legend {
    color: white;
    font-family: sans-serif;
    font-size: large;
    font-weight: bold;
}
.PaymentPrompt {
    margin-top: 5px;
}

.Message {
    font-weight: bold;
    color: var( --color-highlight );
    margin: 2px 0 2px 0;
    min-height: 18px;
}

.AmountInput {
    font-size: large;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    margin-right: 10px;
    width: 150px;
    height: 30px;
    border-radius: 0;
    margin-left: 83px;
}

.Disabled:disabled {
    opacity: .5;
}
