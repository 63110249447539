.Modal {
    top: 10%;
}

.Content {
    border: 1px solid black;
    padding: 10px;
    background-color: white;
}

.PriceOverride {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-top: 10px;
}

.Description {
    margin-right: 10px;
    padding: 6px;
    width: 100%
}

.Price {
    text-align: right;
    padding: 8px;
    max-width: 100px;
}
