.Modal {
    top: 5%;
    left: 5%;
    height: 90%;
    width: 90%;
    padding: 5px;
}

.OrderDetail {
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    height: 100%;
    width: auto;
    background-color: white;
    color: black;
}

.X {
    position: absolute;
    top: -10px;
    right: 0;
    font-size: 40px;
    font-weight: bold;
    color: rgba( 0, 0, 0, 0.3 );
    cursor: pointer;
}

.ContactInfo,
.PickupInfo {
    margin-bottom: 10px;
}

.ContactHeader {
    font-size: larger;
    font-weight: 600;
}

.Field {
    display: flex;
    flex-flow: row;
    width: 100%;
}

.FieldLabel {
    width: 30%;
    font-size: smaller;
    font-style: italic;
    font-weight: 200;
}

.Items {
    overflow-y: auto;
    overflow-x: hidden;
}
