.Connect {
    margin-left: auto;
    background-color: darkslategrey;
}

.NotConnected {
    color: var( --color-highlight )
}

.Disabled:disabled {
    opacity: .5;
}
