.Alerts,
.AlertsFront {
/*    margin-top: 64px; 
    margin-bottom: -64px; */
    position: relative;
    width: 99%;
    border: 2px solid var( --color-highlight );
    background-color: rgba( 0, 0, 0, 0.4 );
}
.AlertsFront {
    z-index: 1000;
}

.Header {
    display: flex;
    color: var( --color-highlight );
    /*text-align: center;*/
    font-size: xx-large;
    font-weight: bold;
    padding: 3px;
    border-bottom: 1px solid var( --color-highlight );
    flex-flow: row;
    justify-content: space-between;
}

.AlertMessage {
    display: flex;
    flex-flow: row;
    font-size: large;
    align-items: center;
}

.Overdue,
.FiveMinutes,
.FifteenMinutes,
.NewOrder {
    margin-left: 5px;
    width: 180px;
    font-size: large;
}

.Overdue {
    color: red;
}
.FiveMinutes {
    color: orange;
}
.FifteenMinutes {
    color: yellow;
}
.NewOrder {
    color: lightcyan;
}

.ItemCount,
.PickupName {
    font-weight: bold;
    font-size: x-large;
    margin-left: 10px;
    margin-right: 10px;
}

.PickupName {
    width: 300px;
}
.ItemCount {
    width: 40px;
}

.NewButton,
.ToggleButton {
    height: 30px;
    padding: 5px;
}
.ToggleButton {
    font-size: medium;
}