/*.Modal {
    width: 100%;
    max-width: 550px;
    left: calc( ( 100vw - 100% ) / 2 );
}*/
.Modal {
    top: 10%;
}

.Content {
    width: 90%;
    left: 5%;
    max-width: 500px;
}

.Messages {
    background-color: white;
    display: flex;
    flex-flow: column;
    padding: 4px;
    max-height: 400px;
    overflow-y: auto;
}

.MessageLine > label {
    font-size: small;
}

.Heading {
    margin-bottom: 4px;
}

.Remove {
    height: 28px;
    width: auto;
    font-size: small;
    padding: 3px;
}

.Buttons {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-size: small;
}

.Cancel {
    margin-left: auto;
}