.Main {
    position: fixed;
    top: 65px;
    left: 0;
    width: calc( 100% - 15px );
    height: calc( 100% - 120px );
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 0 10px 0 5px;
    padding: 5px;
    color: black;
    background-color: white;
    overflow: hidden;
}
.Main > h2 {
    margin-left: 20px;
}

.NoCarts {
    margin-left: 30px;
    margin-bottom: auto;
}

.Orders {
    display: flex;
    flex-flow: column;
    margin-bottom: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.Order {
    display: flex;
    flex-flow: row;
    text-align: center;
    font-size: 15px;
    font-weight: 200;
    margin-bottom: 7px;
    cursor: pointer;
}

.Header {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.Date {
    width: 15%;
}

.ItemsHeader {
    width: 70%;
}
.Items {
    width: 70%;
    font-size: smaller;
    font-style: italic;
}

.Footer {
    display: block;
    width: 100%;
    padding: 5px 10px 5px 5px;
    border-top: 1px solid rgba( 0, 0, 0, 0.3 );
}
