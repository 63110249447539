.Modal {
    background-color: white;
    color: black;
    top: 20%;

}
@media (orientation:landscape) {
    .Modal {
        max-width: 250px;
        left: calc( ( 100% - 250px ) / 2 );
    }
}

.Picture {
    position: relative;
    display:flex;
    flex-flow: row;
    justify-content: space-around;
    padding-top: 10px;
}

.Thumbnail {
    width: 200px;
    height: 200px;
    max-width: 200px;
    margin-right: auto;
    border: 2px solid black;
}
@media (orientation:landscape) {
    .Thumbnail {
        width: 200px;
        height: 200px;
        max-width: 200px;
    }
}
.X {
    position: absolute;
    top: -20px;
    right: -10px;
    font-size: 25px;
    font-weight: bold;
    color: rgba( 0, 0, 0, 0.3 );
    cursor: pointer;
}

.ProductName {
    font-family: 'Tangerine', serif;
    font-size: 35px;
}

.Description {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 8px;
}
