.Variation {
    display: flex;
    flex-flow: column;
    margin-top: 2px;
    font-size: smaller;
}
.VariationLine {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.VariationName {
    width: auto;
}
.Price {
    width: 45px;
    text-align: right;
    margin-left: 5px;
}

.Plus,
.Minus,
.Change {
    height: 17px;
    width: 17px;
    margin: -2px 0 0 5px;
    padding: 1px;
    margin-left: 5px;
}
.Change {
    width: auto;
    font-size: x-small;
}

.Qty {
    margin-left: auto;
}

.OutOfStock {
    margin-left: auto;
    font-weight: bold;
}

.OutOfStockWarning {
    color: var( --color-highlight );
}
.OutOfStockWarning::after {
    color: var( --color-highlight );
    content: "!!"
}

.NotAvailable {
    background-color: white;
}