.Dialog {
    font-size: initial;
}

.Header {
    margin-bottom: 10px;
}

.PickupDate,
.OrderTime {
    height: 30px;
    width: 180px;
    margin: auto;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 5px;
}

.PickupSooner {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    border: 2px solid black;
    background-color: green;
    font-weight: bold;
    padding: 10px;
    margin-bottom: 10px;
}
.PickupLessSoon {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    border: 6px solid black;
    color: black;
    background-color: yellow;
    font-weight: bold;
    font-size: larger;
    padding: 10px;
    margin-bottom: 10px;
}
.PickupLater {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    border: 6px solid black;
    color: black;
    background-color: red;
    font-weight: bold;
    font-size: x-large;
    padding: 10px;
    margin-bottom: 10px;
}

.ClosingTime {
    color: white;
}

.Message {
    height: auto;
    width: auto;
    margin-top: 10px;
    animation: blinker 1s linear infinite;
  }
  
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.OkOrClear {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}