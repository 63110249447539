.TotalLine {
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: flex-end;
}

.TotalLegend {
    margin-right: 5px;
}

.TotalAmount {
    width: 80px;
    margin-right: 5px;
    text-align: right;
}
