.SystemMenu {
    position: fixed;
    z-index: 500;
    background-color: #094609;
    color: lightcyan;
    width: 180px;
    max-width: 70%;
/*    height: 100%; */
    border: none;
    padding: 8px 16px;
    box-sizing: border-box;
    left: 0;
    top: 66px;
    transition: transform 0.3s ease-out;
    display: flex;
    flex-direction: column;
}

.Open {
    transform: translateX(0);
}

.Closed {
    transform: translateX(-100%);
}
