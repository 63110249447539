.ButtonPane {
    min-height: 58px;
}

.Alert {
    font-weight: bold;
    color: var( --color-highlight );
    margin: 5px 0
}
.Info {
    font-weight: bold;
    color: white;
}