/*.Main {
    position: fixed;
    top: 65px;
    left: 0;
    width: calc( 100% - 25px );
    min-height: calc( 100% - 120px );
    height: calc( 100% - 120px );
    overflow: auto;
    display: flex;
    flex-flow: column;
    margin: 0 10px 0 5px;
    padding: 5px;
    color: black;
    background-color: white;
}

.Links {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}
.Links > link:visited {
    color:blue;
}

.Content {
    display: flex;
    flex-flow: column;
    overflow: auto;
    min-height: 380px;
}

.ClosingTime {
    text-align: center;
    margin: 10px;
    padding: 10px;
    border: 1px solid rgba( 255, 0, 0, 0.4 )
}

.ClosingTime > strong {
    color: red;
}

.ContactInfo {
    display: flex;
    flex-flow: column;
    margin-left: 20%;
}
.ContactInfo > input {
    width: 200px;
    height: 20px;
    border-radius: 5px;
    margin-bottom: 3px;
}
.ContactInfo > div {
    font-size: smaller;
    font-weight: bold;
}
.ContactInfo > div > strong {
    color: red;
}

.Prompt {
    max-width: 200px;
    margin-left: auto;
    margin: 10px 10px 5px auto;
    padding: 3px;
    cursor: pointer;
    color: rgb(175, 171, 171);
    border: 1px solid lightgray;
    border-radius: 3px;
}

.TaxCert {
    display: flex;
    flex-flow: row;
    justify-content: center;
}
.TaxCertValue {
    margin-top: 13px;
}
.TaxCertInput {
    padding: 6px;
}

.TipInput {
    text-align: right;
    padding: 8px;
    margin-right: 10px;
}

.InputError {
    border-color: var( --color-highlight );
}
*/

.Disabled:disabled {
    opacity: .5;
}

.PaymentModal {
    width: 370px;
    height: 238px;
    left: calc( ( 100% - 370px) / 2 );
    background-color: white;
}

.PaymentButtons {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

/*
.FooterSpacer {
    height: 47px;
}

.Footer {
    position: absolute;
    z-index: -10;
    bottom: 0;
    left: 0;
    padding: 5px 10px 5px 5px;
    border-top: 1px solid rgba( 0, 0, 0, 0.3 );
    display: flex;
    flex-flow: row;
    height: 47px;
    width: 97%;
    justify-content: space-between;
    justify-items: center;
}

.FooterText {
    margin-top: 8px;
}

.SquareLogo {
    margin-right: 0;
}

.ContinueShopping {
    margin-bottom: 5px;
}

.Messages {
    color: var( --color-highlight );
}
*/