.Legend {
    color: white;
    font-family: sans-serif;
    font-size: large;
    font-weight: bold;
}

.Message {
    font-weight: bold;
    color: var( --color-highlight );
    margin: 2px 0 2px 0;
    min-height: 18px;
}

.BalanceInput,
.CardNumberInput {
    font-size: large;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    margin-right: 10px;
    width: 180px;
    height: 30px;
    border-radius: 0;
    margin-left: 83px;
}
.BalanceInput {
    text-align: right;
}

.Disabled:disabled {
    opacity: .5;
}
