.Main {
    position: fixed;
    top: 65px;
    left: 0;
    width: calc( 100% - 20px );
    height: calc( 100% - 80px );
    min-height: calc( 100% - 80px );
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 0 10px 0 5px;
    padding: 5px;
    color: black;
    background-color: white;
}

.Links {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-right: 3px;
}
.Links > link:visited {
    color:blue;
}

.ContinueShopping {
    margin-bottom: 5px;
}

.OrderPickup {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.DateLabel {
    display: inline-block;
    width: auto;
    font-size: x-small;
    font-style: italic;
    font-weight: 200;
    margin-right: 10px;
}

.DateValue {
    display: inline-block;
    width: auto;
    font-size: 15px;
    margin-right: 20px;
}

.ChangePickup {
    height: 26px;
    font-size: smaller;
    margin-left: auto;
    margin-right: 3px;
    padding: 4px;
    opacity: .7;
}

.Clear {
    margin-right: -3px;
}

.Items {
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.AdminNotesLabel {
    font-weight: bold;
}
.AdminNotes {
    min-height: 100px;
}

.Footer {
    margin-top: auto;
    padding: 5px 10px 5px 5px;
    border-top: 1px solid rgba( 0, 0, 0, 0.3 );
}

.ConfirmEmpty {
    background-color: white;
    color: black;
}

.StoreOrders {
    margin-left: auto;
    display: flex;
    flex-flow: column;
}

.ClearButtons {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.ClearButtons > button {
    margin: 0;
}