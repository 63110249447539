.Legend {
    color: white;
    font-family: sans-serif;
    font-size: large;
    font-weight: bold;
}

.ChangeAmount {
    padding: 5px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: lightgray;
    color: black;
    font-size: x-large;
    font-weight: bold;
}

.ChangeAmountRow {
    opacity: 0.3;
}

.ChangeAmountAmt {
    text-align: right;
}

.CashAmountInput {
    font-size: large;
    font-weight: bold;
    text-align: right;
    padding: 8px;
    margin-right: 10px;
    width: 83px;
    height: 30px;
    border-radius: 0;
    margin-left: 83px;
}

.Disabled:disabled {
    opacity: .5;
}

.Calculator {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    margin-bottom: 10px;
}

.AmountPrompt {
    display: grid;
    grid-template-columns: 100px;
    gap: 10px;
}

.KeyPad {
    display: grid;
    grid-template-columns: 100px repeat( 5, 30px );
    gap: 10px;
}

.Prompt,
.Key {
    color: black;
    background-color: white;
    border: 2px solid black;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 10px;
    font-family: sans-serif;
    font-weight: bold;
}

.Prompt {
    width: 100px
}

.Spacer {
    opacity: 0;
    width: 30px;
}