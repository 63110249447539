.App {
    z-index: -100;
    background-color: #274928;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    font-family: 'Open Sans', Helvetica, sans-serif;
}
